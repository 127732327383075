body {
  margin: 0;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('images/bg_blur.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: 1;
  color: #fff;
}
