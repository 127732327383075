.container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  min-height: 100vh;
  align-items: center;
  padding: 0 100px;
}

@media (max-width: 1300px) {
  .container {
    flex-direction: column;
    padding: 100px 0;
  }

  .container > * {
    margin-top: 50px;
  }

  .container > *:first-child {
    margin-top: 0;
  }
}
