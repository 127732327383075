.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gridBox,
.titleBox {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 25px;
  text-align: center;
}

.titleBox {
  padding: 20px;
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: 500;
}

.gridBox {
  display: grid;
  grid-template-rows:
    [row-a] 1fr
    [row-a-end row-i] 1fr
    [row-i-end row-u] 1fr
    [row-u-end row-e] 1fr
    [row-e-end row-o] 1fr
    [row-o-end row-null] 1fr
    [row-null-end];
  grid-template-columns:
    [col-null] 1fr
    [col-null-end col-k] 1fr
    [col-k-end col-s] 1fr
    [col-s-end col-t] 1fr
    [col-t-end col-n] 1fr
    [col-n-end col-h] 1fr
    [col-h-end col-m] 1fr
    [col-m-end col-y] 1fr
    [col-y-end col-r] 1fr
    [col-r-end col-w] 1fr
    [col-w-end];
}

.kana {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 50px;
  font-size: 1.75em;
  font-weight: 300;
  margin: 15px;
}

@media (max-width: 1300px) {
  .titleBox {
    padding: 5px;
    font-size: 1.5em;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .gridBox,
  .titleBox {
    border-radius: 10px;
  }

  .gridBox {
    width: 75vw;
  }

  .kana {
    height: 5vh;
    width: 7.5vw;
    font-size: 1.25em;
    margin: 12px 0;
  }
}

@media (max-width: 800px) {
  .gridBox {
    width: 95vw;
  }

  .kana {
    width: 9.5vw;
    font-size: 1em;
  }
}
